/* You can add global styles to this file, and also import other style files */

// --- Node Package Imports

@import '@nwcode/bolt-bootstrap-theme/src/bolt-bootstrap.scss';

// --- Common Helpers

.no-outline {
  outline: none;
}

// --- App

html,
body {
  margin: 0;
  height: 100%;
}

// --- Alerts

bolt-alert-error .alert {
  @extend .mb-3;
}

bolt-alert-error .alert .media-body {
  @extend .mb-0;
}

bolt-field-error .alert .media-body {
  line-height: 1.5rem;
}

.field-error {
  @extend .mb-0;
}

// --- Sections

.page-content {
  min-height: 35rem;
}

.content-section {
  margin-bottom: 2rem;
}

.pre-form-content {
  @extend .mb-3;
}

.pre-form-content > :last-child {
  @extend .mb-0;
}

.button-container {
  @extend .pt-4, .pb-4;
  background-color: #f0f0ee;
  border-top: 1px solid #bcbdbc;
}

.section-separator {
  border-bottom: 1px solid #bcbdbc;
}

// --- Text

.help-text {
  @extend .help-block;
  color: #716969;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0.5rem 0 0 0;
}

.underline {
  text-decoration: underline;
}

.webkit-appearance-none {
  -webkit-appearance: none;
}

// --- Forms

.form-group {
  margin-bottom: 1rem;
}

// --- Inputs

div[id$='-textfield-col']:not([id^='mobile-number-']):not([id^='enter-code-']) {
  @extend .col-sm-6, .col-md-5, .col-lg-4;
}

.code-text-field {
  min-width: 14.5rem;
  max-width: 14.5rem;
  height: 4rem;
  padding-left: 1.9rem;
  font-family: var(--font-family-monospace);
  letter-spacing: 0.75rem;
  font-size: 2rem;
  border: 1px solid #8a8a8a;
  border-radius: 0.25rem;
}

.mobile-number-field {
  min-width: 11rem;
  max-width: 11rem;
}

.mobile-friendly-radio {
  margin-bottom: 0.75rem !important;
}

// --- Buttons

.btn:focus {
  @extend .no-outline;
}

.btn-group {
  @extend .mt-2;
}

.btn-group label,
.modal-footer button,
.modal-footer input,
.button-standalone button,
.button-standalone input,
.button-stack button,
.button-stack input {
  @extend .btn-lg;
}

.modal-footer,
.button-stack {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-start;
}

.button-standalone {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.modal-footer > :not(:last-child),
.button-stack > :not(:last-child) {
  @extend .ml-3;
}

.modal-footer > :first-child,
.button-stack > :first-child {
  margin-left: auto !important;
}

@media screen and (max-width: 575px) {
  .modal-footer,
  .button-standalone,
  .button-stack {
    flex-direction: column;
  }

  .modal-footer button,
  .modal-footer input,
  .button-stack button,
  .button-standalone button,
  .button-stack input {
    margin-left: 0rem !important;
    width: 100%;
  }

  .modal-footer > :not(:last-child),
  .modal-footer > :first-child,
  .button-stack > :not(:last-child),
  .button-stack > :first-child {
    margin-left: 0rem !important;
  }

  .modal-footer > :not(:first-child),
  .button-stack > :not(:first-child) {
    margin-top: 1rem !important;
  }

  .modal-footer {
    align-items: unset;
  }
}

  .pref__spinner__overlay__container {
    position: absolute;
    z-index: 997;
  }
  .pref__spinner__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
    z-index: 998;
  }
  
  //Spinner Overlay css
  .pref__spinner__image {
    // background-image: url('assets/images/wait-spinner.gif');
    background-repeat: no-repeat;
    width: 49px;
    height: 49px;
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
  }
  .pref__spinner__message {
    color: #000;
    display: inline-block;
  }
  .pref__spinner__container {
    position: relative;
    margin: 0 auto;
    top: 30%;
    z-index: 999;
  }

